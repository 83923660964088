import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import {
  fetchAllDonors1,
  fetchAllDonors,
  fetchStudentsByDonor,
} from "../../../utils/api-factory"; // Ensure you import the actual API functions
import downloadIcon from './../../../assets/Downloads.png';
import shareIcon from './../../../assets/Share.png';
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from 'react-redux';

const MarketingExtension = () => {
  const [donors, setDonors] = useState([]);
  const [expandedDonorId, setExpandedDonorId] = useState(null); // Track which donor is expanded
  const [beneficiaries, setBeneficiaries] = useState({}); // Store beneficiaries for each donor
  const [loading, setLoading] = useState(false); // Track loading state for beneficiaries
  const [error, setError] = useState(null);
  const [errorStyle, setErrorStyle] = useState({});
  const navigate = useNavigate(); // Initialize navigate function

  const [showReports, setShowReports] = useState(false); // Visibility of the hovering window
  const [reports, setReports] = useState([]); // Store reports for the selected donor

  const [sharing, setSharing] = useState(false);

  // const [donors, setDonors] = useState([]); // State to store fetched donors
  const [selectedDonor, setSelectedDonor] = useState("");
  // const [error, setError] = useState(null);
  // const [sharing, setSharing] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  // const [selectedDonorId, setSelectedDonorId] = useState(""); // For tracking the selected donor ID

  const [selectedReportId, setSelectedReportId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  // const [donors, setDonors] = useState([]); // For dropdown donor list

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const currentUser = useSelector((state) => state.user?.currentUser);

  useEffect(() => {
    // Fetch donors on component mount
    fetchAllDonors(
      currentUser._id,
      (data) => {
        setDonors(data); // Store donors in state
      },
      (err) => {
        console.error("Error fetching donors:", err);
        setError("Unable to fetch donors. Please try again later.");
      }
    );
  }, []);

  const fetchBeneficiaries = (donor_id) => {
    // console.log("Fetching beneficiaries for donor ID:", donor_id);

    if (beneficiaries[donor_id]) {
      setExpandedDonorId(expandedDonorId === donor_id ? null : donor_id);
      return;
    }

    setLoading(true);
    fetchStudentsByDonor(
      donor_id,
      (data) => {
        // console.log("API Response for beneficiaries:", data); // Log the full response
        setBeneficiaries((prev) => ({
          ...prev,
          [donor_id]: data.length > 0 ? data : [], // Handle empty data
        }));
        setLoading(false);
        setExpandedDonorId(donor_id);
      },
      (err) => {
        console.error("Error fetching beneficiaries:", err);
        setError(`Unable to fetch beneficiaries for donor ID: ${donor_id}`);
        setLoading(false);
      }
    );
  };

  // const navigateToImpactReport = (donorName, students) => {
  //   navigate("/impact-report", { state: { donorName, students } });
  // };
  // const fetchReports = async (donor_id) => {
  //   try {
  //     const REPORTS_API_URL = `http://localhost:5000/api/Impact-Reports?donorId=${donor_id}`;
  //     const response = await fetch(REPORTS_API_URL, {
  //       cache: 'no-cache',  // Force a fresh request
  //     });

  //     // Log the raw response for debugging
  //     const text = await response.text();
  //     console.log("Response:", text);  // See the raw response

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = JSON.parse(text);  // Manually parse the response text
  //     setReports(data);
  //     setShowReports(true);
  //   } catch (error) {
  //     console.error("Error fetching reports:", error);
  //     setError("Unable to fetch reports. Please try again later.");
  //   }
  // };

  const fetchReports = async (donor_id) => {
    try {
      const REPORTS_API_URL = `${process.env.REACT_APP_BASE_URL}/api/Impact-Reports?donorId=${donor_id}`;
      const response = await fetch(REPORTS_API_URL, {
        cache: "no-cache", // Force a fresh request
      });

      const text = await response.text();
      // console.log("Response:", text); // Debug the raw response

      if (!response.ok) {
        // Check if the response is a known error message
        const errorData = JSON.parse(text);
        if (errorData.error && errorData.error.includes("No reports found for this donor.")) {
          setError("No reports found under this donor.");
          setErrorStyle({ color: "red", fontWeight: "bold" });
          setReports([]);
          setShowReports(false);
          return; // Stop further processing
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = JSON.parse(text);

      if (!data || data.length === 0) {
        setError("No reports found under this donor.");
        setErrorStyle({ color: "red", fontWeight: "bold" });
        setReports([]);
        setShowReports(false);
      } else {
        setError(null);
        setErrorStyle({});
        setReports(data);
        setShowReports(true);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
      // Display this error only when there's a genuine fetch issue
      setError("Unable to fetch reports. Please try again later.");
      setErrorStyle({ color: "red", fontWeight: "bold" });
      setReports([]);
      setShowReports(false);
    }
  };

 
  const handleShareButtonClick = async (reportId) => {
    setSelectedReportId(reportId);
    setModalVisible(true); // Open the modal

    // Set loading to true before making the API call
    setLoading(true);

    try {
      // Use your existing fetchAllDonors1 method to fetch donors
      fetchAllDonors(
        currentUser._id,
        (data) => {
          // console.log('Fetched donors:', data);
          setDonors(data); // Update the donors state with the fetched data
          setLoading(false); // Set loading to false when data is received
        },
        (err) => {
          console.error('Error fetching donors:', err);
          setLoading(false); // Set loading to false in case of error
          alert('Failed to load donors. Please try again later.');
        }
      );
    } catch (error) {
      console.error('Error fetching donors:', error);
      setLoading(false); // Set loading to false in case of an error
      alert('Failed to fetch donors.');
    }
  };



  const setupOAuth = async () => {
    try {
      // console.log('Starting OAuth setup for NGO:', currentUser._id);
      // Make sure this URL matches your backend route
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/oauth/setup`, {
        params: { ngoId: currentUser._id }
      });
      console.log('OAuth setup response:', response.data);
      if (!response.data.authUrl) {
        throw new Error('No auth URL received from server');
      }
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Detailed OAuth setup error:', error.response || error);
      alert(`Failed to set up email sharing: ${error.response?.data?.message || error.message}`);
    }
  };



  const handleShare = async () => {
    // console.log("selectedDonor: ", selectedDonor);
    // console.log("selectedReport: ", selectedReportId);
    if (!selectedDonor || !selectedReportId) {
      // console.log("SHOW AI inside handleShare function");
      setError("Please select a donor");
      return;
    }
  
    try {
      setSharing(true);
      setError(null);
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/IA0.5_reports/share`,
        {
          reportId: selectedReportId,
          donorId: selectedDonor,
          ngoId: currentUser._id
        }
      );
  
      // console.log('Share response:', response.data);
      alert(response.data.message || "Report shared successfully!");
      handleCloseModal();
    } catch (error) {
      console.error("Error sharing report:", error.response || error);
      if (error.response?.data?.message === 'NGO email not configured') {
        // Prompt user to set up OAuth
        if (window.confirm('Email sharing not configured. Would you like to set it up now?')) {
          setupOAuth();
        }
      } else {
        setError(
          error.response?.data?.message || 
          "Failed to share report. Please try again."
        );
      }
    } finally {
      setSharing(false);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedDonor("");
    setSelectedReport(null);
    setError(null);
  };


  return (
    <div style={{ padding: "20px" }}>
      <h2>Marketing Extension</h2>
      {error && <p style={errorStyle}>{error}</p>}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ background: "#f4f4f4" }}>
            <th style={{ border: "1px solid #ddd", padding: "10px" }}>
              Serial Number
            </th>
            <th style={{ border: "1px solid #ddd", padding: "10px" }}>
              Donor Name
            </th>
            <th style={{ border: "1px solid #ddd", padding: "10px" }}>
              Students & Beneficiaries
            </th>
          </tr>
        </thead>
        <tbody>
          {donors.map((donor, index) => (
            <React.Fragment key={donor._id}>
              <tr>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                  {donor.name}
                </td>
                <td
                  style={{
                    border: "1px solid #ddd",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <button
                      style={{
                        background: "#FFF3CD",
                        border: "none",
                        padding: "10px 15px",
                        cursor: "pointer",
                      }}
                      onClick={() => fetchBeneficiaries(donor._id)}
                    >
                      {expandedDonorId === donor._id
                        ? "Hide Beneficiary List"
                        : "Get Beneficiary List"}
                    </button>
                    <button
                      style={{
                        background: "#D4EDDA",
                        border: "none",
                        padding: "10px 15px",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        const IMPACT_REPORT_URL = process.env.REACT_APP_FRONTEND_API_URL_IA0_5;
                        // console.log("IMPACT_REPORT_URL: ", IMPACT_REPORT_URL);
                        const url = `${IMPACT_REPORT_URL}/?donorId=${donor._id}`;
                        // console.log(" const URL: ", url);
                        window.open(url, "_blank");
                      }}
                    >
                      Generate Students Impact Report
                    </button>

                    <button
                      onClick={() => fetchReports(donor._id)}
                      className="report-btn"
                      id="viewbtn"
                    >
                      View Reports
                    </button>

                  </div>
                  {expandedDonorId === donor._id && (
                    <div
                      style={{
                        marginTop: "10px",
                        background: "#f9f9f9",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <h4>Beneficiaries:</h4>
                      {loading ? (
                        <p>Loading beneficiaries...</p>
                      ) : beneficiaries[donor._id]?.length > 0 ? (
                        <ul style={{ margin: 0, paddingLeft: "20px" }}>
                          {beneficiaries[donor._id].map((student) => (
                            <li key={student._id}>
                              {student.name} - Class:{" "}
                              {student.sclassName?.sclassName || "N/A"} - Roll
                              No: {student.rollNum || "N/A"}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No beneficiaries found for this donor.</p>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {/* {error && <p style={errorStyle}>{error}</p>} */}
      {showReports && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            padding: "20px",
            zIndex: 1000,
            borderRadius: "8px",
            width: "60%",
            maxHeight: "80%",
            overflowY: "auto",
          }}
        >
          <h3>Impact Reports</h3>
          {reports.length > 0 ? (
            <ul style={{ padding: 0, listStyle: "none" }}>
              {reports.map((report, idx) => (
                <li
                  key={report._id}
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #ddd",
                    marginBottom: "10px",
                  }}
                >
                  <p>
                    <strong>Title:</strong> {report.reportName || "No title available"}
                  </p>
                  <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                    <button
                      style={{
                        background: "#D4EDDA",
                        border: "none",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const reportPath = report.reportData[0]?.reportPath || "#";
                        window.open(reportPath, "_blank");
                        console.log("Opening report in new tab:", report.reportName);
                      }}
                    >
                      <img
                        src={downloadIcon}
                        alt="Download"
                        style={{ width: "16px", marginRight: "5px" }}
                      />
                      Download
                    </button>
                    <button
                      style={{
                        background: "#FFF3CD",
                        border: "none",
                        padding: "5px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShareButtonClick(report._id)}
                    >
                      <img
                        src={shareIcon}
                        alt="Share"
                        style={{ width: "16px", marginRight: "5px" }}
                      />
                      {sharing ? "Sharing..." : "Share Report"}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No reports available for this donor.</p>
          )}
          <button
            style={{
              background: "#f44336",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              marginTop: "20px",
              borderRadius: "5px",
            }}
            onClick={() => setShowReports(false)}
          >
            Close
          </button>
        </div>
      )}


      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h3>Share Report with Donor</h3>
            {loading ? (
              <p>Loading donors...</p>
            ) : error ? (
              <p className="error-message">{error}</p>
            ) : (
              <>
                <select
                  value={selectedDonor}
                  onChange={(e) => setSelectedDonor(e.target.value)}
                  className="donor-select"
                >
                  <option value="">Select a Donor</option>
                  {Array.isArray(donors) &&
                    donors.map((donor) => (
                      <option key={donor._id} value={donor._id}>
                        {donor.name} ({donor.email})
                      </option>
                    ))}
                </select>

                <div className="modal-actions">
                  <button
                    onClick={handleCloseModal}
                    className="close-button"
                    disabled={sharing}
                  >
                    Cancel
                  </button>
                  <button
                     onClick={() => handleShare(donors._id)}
                    className="share-confirm-button"
                    disabled={!selectedDonor || sharing}
                  >
                    {sharing ? "Sharing..." : "Share Report"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>


  );
};

export default MarketingExtension;






// import React from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from "react-router-dom";
// import { useSelector } from "react-redux";
// import Homepage from "./pages/Homepage";
// import NGODashboard from "./pages/ngo/NGODashboard";
// import StudentDashboard from "./pages/student/StudentDashboard";
// import TeacherDashboard from "./pages/teacher/TeacherDashboard";
// import LoginPage from "./pages/LoginPage";
// import NGORegisterPage from "./pages/ngo/NGORegisterPage";
// import ChooseUser from "./pages/ChooseUser";
// import AdminDashboard from "./pages/admin/adminDasboard";
// import NoticeDetail from "./pages/noticeDetail";
// import Dashboard from "./pages/admin/Dashboard";

// const App = () => {
//   const { currentRole } = useSelector((state) => state.user);

//   return (
//     <Router>
//       {currentRole === null && (
//         <Routes>
//           <Route path="/" element={<Homepage />} />
//           <Route path="/choose" element={<ChooseUser visitor="normal" />} />
//           <Route
//             path="/chooseasguest"
//             element={<ChooseUser visitor="guest" />}
//           />
//           <Route path="/adminDashboard" element={<Dashboard />} />

//           <Route path="/adminlogin" element={<LoginPage role="Admin" />} />
//           <Route path="/NGOlogin" element={<LoginPage role="NGO" />} />
//           {/* <Route path="/Studentlogin" element={<LoginPage role="Student" />} /> */}
//           <Route path="/Teacherlogin" element={<LoginPage role="Teacher" />} />

//           <Route path="/NGOregister" element={<NGORegisterPage />} />

//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       )}

//       {currentRole === "admin" && (
//         <>
//           <AdminDashboard />
//         </>
//       )}
//       {currentRole === "NGO" && (
//         <>
//           <NGODashboard />
//         </>
//       )}

//       {currentRole === "Student" && (
//         <>
//           <StudentDashboard />
//         </>
//       )}

//       {currentRole === "Teacher" && (
//         <>
//           <TeacherDashboard />
//         </>
//       )}
//     </Router>
//   );
// };

// export default App;



import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import Homepage from "./pages/Homepage";
import NGODashboard from "./pages/ngo/NGODashboard";
import StudentDashboard from "./pages/student/StudentDashboard";
import TeacherDashboard from "./pages/teacher/TeacherDashboard";
import LoginPage from "./pages/LoginPage";
import NGORegisterPage from "./pages/ngo/NGORegisterPage";
import ChooseUser from "./pages/ChooseUser";
import AdminDashboard from "./pages/admin/adminDasboard";
import NoticeDetail from "./pages/noticeDetail";
import Dashboard from "./pages/admin/Dashboard";
import { setUser } from "./redux/userRelated/userSlice"; // Adjust import path as needed
import { loadState } from './redux/LocalStorage';


const AppWrapper = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const { currentRole } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const ngoId = params.get('ngoId');
    const skipLogin = params.get('skipLogin');
    
    if (token && ngoId && skipLogin === 'true') {
      // Store token
      localStorage.setItem('token', token);
      localStorage.setItem('ngoId', ngoId);
      // Update Redux state
      dispatch(setUser({
        role: 'NGO',
        _id: ngoId,
        token
      }));
      
      
      // Clean URL
      navigate('/NGO/dashboard', { replace: true });
      // navigate("/ngoHomepage", { state: { user: ngoId } });
    }

  }, [location, navigate, dispatch,]);

  
  // Handle protected routes
  const ProtectedRoute = ({ children, allowedRole }) => {
    if (!currentRole) {
      return <Navigate to="/NGOlogin" />;
    }
    if (currentRole !== allowedRole) {
      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <>
      {currentRole === null && (
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/choose" element={<ChooseUser visitor="normal" />} />
          <Route
            path="/chooseasguest"
            element={<ChooseUser visitor="guest" />}
          />
          <Route path="/adminDashboard" element={<Dashboard />} />

          <Route path="/adminlogin" element={<LoginPage role="Admin" />} />
          <Route path="/NGOlogin" element={<LoginPage role="NGO" />} />
          <Route path="/Teacherlogin" element={<LoginPage role="Teacher" />} />

          <Route path="/NGOregister" element={<NGORegisterPage />} />

          {/* Add route for handling redirect from frontend 1 */}
          <Route 
            path="/NGO/dashboard" 
            element={
              <ProtectedRoute allowedRole="NGO">
                <NGODashboard />
              </ProtectedRoute>
            } 
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}

      {currentRole === "admin" && <AdminDashboard />}
      {currentRole === "NGO" && <NGODashboard />}
      {currentRole === "Student" && <StudentDashboard />}
      {currentRole === "Teacher" && <TeacherDashboard />}
    </>
  );
};

export default AppWrapper;


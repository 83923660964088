// React Component Update
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// export default function ShowAnnualReport() {
//   const [reports, setReports] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetchReports();
//   }, []);

//   const fetchReports = async () => {
//     try {
//       console.log("Calling out to fetch reports");
//       const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/reports`);
//       console.log("Fetched reports:", response.data);
//       setReports(response.data);
//     } catch (error) {
//       console.error("Error fetching reports:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

  // const handleOpenFlaskPage = () => {
  //   window.location.href = `${process.env.REACT_APP_FLASK_API_URL}`;
  // };



//   const handleViewReport = async (reportId) => {
//     try {
//       console.log("inside handleViewReport function");
//       setLoading(true);
      
//       // Get PDF directly as blob
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/view`, 
//         { responseType: 'blob' }  // Important: tell axios to expect binary data
//       );
      
//       // Create blob URL
//       const blob = new Blob([response.data], { type: 'application/pdf' });
//       const url = window.URL.createObjectURL(blob);
      
//       // Open PDF in new tab
//       window.open(url, '_blank');
      
//       // Clean up
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error('Error viewing report:', error);
//     } finally {
//       setLoading(false);
//     }
//   };



//   const handleDownloadReport = async (reportId) => {
//     try {
//       setLoading(true);
      
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/view`, 
//         { responseType: 'blob' }
//       );
      
//       // Create download link
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', `report_${reportId}.pdf`);
//       document.body.appendChild(link);
//       link.click();
      
//       // Clean up
//       link.parentNode.removeChild(link);
//       window.URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error('Error downloading report:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleShare = (reportId) => {
//     const shareUrl = `${window.location.origin}/reports/${reportId}`;
//     navigator.clipboard.writeText(shareUrl);
//     alert("Report link copied to clipboard!");
//   };

//   return (
//     <div className="p-4">
//       <button
//         onClick={handleOpenFlaskPage}
//         className="bg-blue-500 text-white px-4 py-2 rounded"
//       >
//         Generate New Impact Report
//       </button>

//       <div className="mt-6">
//         <h2 className="text-xl font-bold mb-4">Generated Reports</h2>
//         {loading ? (
//           <p>Loading reports...</p>
//         ) : reports.length > 0 ? (
//           <ul className="list-disc pl-5">
//             {reports.map((report) => (
//               <li key={report._id} className="mb-4 flex items-center">
//                 <span className="mr-4">{report._id}</span>
//                 <button 
//                   onClick={() => handleViewReport(report._id)}
//                   className="bg-green-500 text-white px-2 py-1 rounded mr-2"
//                 >
//                   View Report
//                 </button>
//                 <button
//                   onClick={() => handleDownloadReport(report._id)}
//                   className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
//                 >
//                   Download Report
//                 </button>
//                 <button
//                   onClick={() => handleShare(report._id)}
//                   className="bg-blue-500 text-white px-2 py-1 rounded"
//                 >
//                   Share Report
//                 </button>
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p>No reports available.</p>
//         )}
//       </div>
//     </div>
//   );
// }





import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ShowAnnualReport.css"; // Custom styles
import downloadIcon from './../../../assets/Downloads.png';
import shareIcon from './../../../assets/Share.png';
import { useDispatch, useSelector } from "react-redux";
// import { fetchAllDonors1 } from "../../../utils/api-factory";
import { fetchAllDonors } from "../../../utils/api-factory";
// require('dotenv').config();

export default function ShowAnnualReport() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("IA1");
  const { currentUser } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling modal visibility
  const [donors, setDonors] = useState([]); // State to store fetched donors
  const [selectedDonor, setSelectedDonor] = useState("");
  const [error, setError] = useState(null);
  const [sharing, setSharing] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  useEffect(() => {
    fetchReports();
  }, []);

  const ngoId = currentUser._id;  // Replace this with the actual NGO ID you want to send
  console.log("ngoid in annual reports",ngoId);

  const handleOpenFlaskPage = () => {
    const flaskUrl =
      selectedOption === "IA1"
        ? `${process.env.REACT_APP_FLASK_API_URL_IA1}/?ngo_id=${ngoId}`
        : `${process.env.REACT_APP_FLASK_API_URL_IA2}/?ngo_id=${ngoId}`;
    window.location.href = flaskUrl;
  };
  const handleDonorChange = (event) => {
    setSelectedDonor(event.target.value);
  };


  // const fetchReports = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/reports`);
  //     setReports(response.data);
  //   } catch (error) {
  //     console.error("Error fetching reports:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchReports = async () => {
    try {
      if (!ngoId) {
        console.error("NGO ID is not available.");
        return;
      }

    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/reports/${ngoId}`
      );
      setReports(response.data);
    } catch (error) {
      console.error("Error fetching reports:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleViewReport = async (reportId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/view`,
        { responseType: "blob" }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error viewing report:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadReport = async (reportId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/reports/${reportId}/view`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${reportId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading report:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleShare = (reportId) => {
  //   const shareUrl = `${window.location.origin}/reports/${reportId}`;
  //   navigator.clipboard.writeText(shareUrl);
  //   alert("Report link copied to clipboard!");
  // };


  

  const handleShareButtonClick = async (reportID) => {
    setSelectedReport(reportID);
    setIsModalOpen(true); // Open the modal when the Share button is clicked

    // Fetch the list of donors from the backend
    setLoading(true);
    fetchAllDonors(
      currentUser._id,
      (data) => {
        console.log("the data recived for annual donor is" , data);
        setDonors(data);
         // Set the donor data in state
        setLoading(false);
      },
      (err) => {
        setError("Failed to load donors. Please try again later.", err);
        setLoading(false);
      }
    );
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDonor("");
    setSelectedReport(null);
    setError(null);
  };
  // const setupOAuth = async () => {
  //   try {
  //     console.log('Starting OAuth setup for NGO:', currentUser._id);
  //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/oauth/setup`, {
  //       params: { ngoId: currentUser._id }
  //     });
  //     console.log('OAuth setup response:', response.data);
  //     if (!response.data.authUrl) {
  //       throw new Error('No auth URL received from server');
  //     }
  //     window.location.href = response.data.authUrl;
  //   } catch (error) {
  //     console.error('Detailed OAuth setup error:', error.response || error);
  //     alert(`Failed to set up email sharing: ${error.response?.data?.message || error.message}`);
  //   }
  // };

  const setupOAuth = async () => {
    try {
      console.log('Starting OAuth setup for NGO:', currentUser._id);
      // Make sure this URL matches your backend route
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/oauth/setup`, {
        params: { ngoId: currentUser._id }
      });
      console.log('OAuth setup response:', response.data);
      if (!response.data.authUrl) {
        throw new Error('No auth URL received from server');
      }
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Detailed OAuth setup error:', error.response || error);
      alert(`Failed to set up email sharing: ${error.response?.data?.message || error.message}`);
    }
  };
  
  // Modify your handleShare function
  const handleShare = async () => {
    if (!selectedDonor || !selectedReport) {
      console.log("inside handleShare function");
      setError("Please select a donor");
      return;
    }
  
    try {
      setSharing(true);
      setError(null);
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/reports/share`,
        {
          reportId: selectedReport,
          donorId: selectedDonor,
          ngoId: currentUser._id
        }
      );
  
      console.log('Share response:', response.data);
      alert(response.data.message || "Report shared successfully!");
      handleCloseModal();
    } catch (error) {
      console.error("Error sharing report:", error.response || error);
      if (error.response?.data?.message === 'NGO email not configured') {
        // Prompt user to set up OAuth
        if (window.confirm('Email sharing not configured. Would you like to set it up now?')) {
          setupOAuth();
        }
      } else {
        setError(
          error.response?.data?.message || 
          "Failed to share report. Please try again."
        );
      }
    } finally {
      setSharing(false);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Annual Impact Reporting</h1>

      <div className="dropdownBox">
        <select
          className="dropdown"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="IA1">IA1</option>
          <option value="IA2">IA2</option>
        </select>

      </div>
      <div id="generateButton">
      <button onClick={handleOpenFlaskPage} className="generate-btn">
          Generate Impact Report
        </button>
        </div>
        <div className="slimLine"></div>
    <div className="reportlistheads">
    <p id="generatedreports" className="subtitle">Generated Reports</p>
    <p id="generatedreports" className="subtitle"> Date created </p>
    <p  id="generatedreports" className="subtitle"> Actions </p>
    </div>
      {loading ? (
        <p>Loading reports...</p>
      ) : reports.length > 0 ? (
        <ul className="report-list">
          {reports.map((report) => (
            <li key={report._id} className="report-item">
              <div className="report-details">
                <span className="report-title">Report #{report._id.slice(0, 5)}..</span>
              </div>
              <div className="report-date">
                {new Date(report.createdAt).toISOString().split("T")[0]}
              </div>
              <div className="report-actions">
                <button
                  onClick={() => handleViewReport(report._id)}
                  className="report-btn"
                  id="viewbtn"
                >
                  View
                </button>
                <button
                  onClick={() => handleDownloadReport(report._id)}
                  className="report-btn"
                >
                  <img src={downloadIcon} alt="Download" className="icon-image" />
                </button>
                <button
                  onClick={() => handleShareButtonClick(report._id)}
                  className="report-btn"
                >
                  <img src={shareIcon} alt="Share" className="icon-image" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No reports available.</p>
      )}
    {/* Share Modal */}
    {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Share Report with Donor</h3>
            {loading ? (
              <p>Loading donors...</p>
            ) : error ? (
              <p className="error-message">{error}</p>
            ) : (
              <>
                <select
                  value={selectedDonor}
                  onChange={(e) => setSelectedDonor(e.target.value)}
                  className="donor-select"
                >
                  <option value="">Select a Donor</option>
                  {Array.isArray(donors) &&
                    donors.map((donor) => (
                      <option key={donor._id} value={donor._id}>
                        {donor.name} ({donor.email})
                      </option>
                    ))}
                </select>

                <div className="modal-actions">
                  <button
                    onClick={handleCloseModal}
                    className="close-button"
                    disabled={sharing}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleShare}
                    className="share-confirm-button"
                    disabled={!selectedDonor || sharing}
                  >
                    {sharing ? "Sharing..." : "Share Report"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}





























// Ashish frontend

// import React, { useState } from "react";
// import "./AnnualImpactReport.css"; // Assuming you have styles defined here
// import downloadIcon from './../../../assets/Downloads.png'; 
// import shareIcon from './../../../assets/Share.png'; // Adjust the file extension as needed
// // Adjust the file extension as needed
// const AnnualImpactReport = () => {
//   const [reports, setReports] = useState([]);
//   const [selectedOption, setSelectedOption] = useState("IA1");

//   const fetchReports = () => {
//     // Simulating fetching reports
//     const fetchedReports = [
//       { id: 234, name: "Report #234", date: "12/11/2024" },
//     ];
//     setReports(fetchedReports);
//   };

//   const handleDropdownChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   return (
//     <div className="outer-container">
//       <div className="container">
//         <h1 className="title">Annual Impact Reporting</h1>
//         <div className="dropdown">
//           <select 
//             className="dropdown-select" 
//             value={selectedOption} 
//             onChange={handleDropdownChange}
//           >
//             <option value="IA1">IA1</option>
//             <option value="IA2">IA2</option>
//           </select>
//         </div>

//         <div className="generate-section">
//           <button className="generate-button" onClick={fetchReports}>
//             Generate Impact Report
//           </button>
//           <div className="icon-group">
//             <button className="icon-button download" title="Download">
//               <img src={downloadIcon} alt="Download" className="icon-image" />
//             </button>
//             <button className="icon-button share" title="Share">
//               <img src={shareIcon} alt="Share" className="icon-image" />
//             </button>
//           </div>
//         </div>

//         <div className="reports-section">
//           <h2 className="subtitle">Past reports</h2>
//           {reports.length === 0 ? (
//             <p>No reports available</p>
//           ) : (
//             <table className="reports-table">
//               <thead>
//                 <tr>
//                   <th>Report Name</th>
//                   <th>Date Created</th>
//                   <th>Actions</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {reports.map((report) => (
//                   <tr key={report.id}>
//                     <td>{report.name}</td>
//                     <td>{report.date}</td>
//                     <td>
//                       <button className="icon-button download" title="Download">
//                         <img src={downloadIcon} alt="Download" className="icon-image" />
//                       </button>
//                       <button className="icon-button share" title="Share">
//                         <img src={shareIcon} alt="Share" className="icon-image" />
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AnnualImpactReport;
import axios from "axios";
import {
  getRequest,
  getSuccess,
  getFailed,
  getError,
  getFailedTwo,
} from "./ngoSlice.js";

export const getAllNgo = () => async (dispatch) => {
  dispatch(getRequest());
  console.log("getting all ngos");
  try {
    const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/allNgo`);
    if (result.data.message) {
      console.log("failed in : ", result.data.message);
      dispatch(getFailedTwo(result.data.message));
    } else {
      console.log("success in : ", result.data);
      dispatch(getSuccess(result.data));
    }
  } catch (error) {
    dispatch(getError(error));
  }
};

export const addNgo = (payload, callback, onError) => async (dispatch) => {
  // dispatch(getRequest());
  console.log("adding ngo with payload ", payload);
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/NGOReg`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("result is : ", result);
    if (result.request.status === 200) { //i am chaing the logic here, as the result.data.message would always be true if the axios api gives resgistration as true or false, so i am setting it to the status code to verify it 200 for successfull registration.
      console.log("success in : ", result.data);
      callback(true);
    } else {
      console.log("failed in : ", result.data.message);
      // dispatch(getFailedTwo(result.data.message));
      onError(result.data.message);
    }
  } catch (error) {
    onError("Something went wrong");
  }
};


// export const addNgo = (payload, callback, onError) => async (dispatch) => {
//   console.log("adding ngo with payload ", payload);
//   try {
//     const result = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}/NGOReg`,
//       payload,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );
//     console.log("result is : ", result);
    
//     // Check status and pass the success message to callback
//     if (result.status === 200) {
//       console.log("success: ", result.data);
//       callback(true, result.data.message || "NGO registered successfully");
//     } else {
//       console.log("failed: ", result.data.message);
//       onError(result.data.message || "Registration failed");
//     }
//   } catch (error) {
//     const errorMessage = error.response?.data?.message || "Something went wrong";
//     onError(errorMessage);
//   }
// };



export const getNgoDetails = (ngoId, callback, onError) => async () => {
  // dispatch(getRequest());
  // console.log("adding ngo with payload ", payload);
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/NGO/${ngoId}`
    );
    if (result.data.message) {
      console.log("failed in : ", result.data.message);
      // dispatch(getFailedTwo(result.data.message));
      onError(result.data.message);
    } else {
      console.log("success in : ", result.data);
      callback(result.data);
    }
  } catch (error) {
    onError("Something went wrong");
  }
};
export const removeNgo = (ngoId, callback, onError) => async (dispatch) => {
  // dispatch(getRequest());
  // console.log("adding ngo with payload ", payload);
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/removeNgo/${ngoId}`,
      {}
    );
    console.log("result is : ", result);
    console.log("result data is : ", result.data);
    if (result.data === "success") {
      console.log("success in : ", result.data);
      dispatch(getAllNgo());
      callback(result.data);
    } else {
      console.log("failed in success");
      // console.log("failed in : ", result.data.message);
      // dispatch(getFailedTwo(result.data.message));
      onError(result.data.message);
    }
  } catch (error) {
    console.log("error is : ", error);
    onError("Something went wrong");
  }
};

export const updateNgo = (payload, callback, onError) => async (dispatch) => {
  // dispatch(getRequest());
  console.log("updating ngo with payload ", payload);
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/updateNgo`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (result.data.message) {
      console.log("failed in : ", result.data.message);
      // dispatch(getFailedTwo(result.data.message));
      onError(result.data.message);
    } else {
      console.log("success in : ", result.data);
      dispatch(getAllNgo());
      callback(true);
    }
  } catch (error) {
    onError("Something went wrong");
  }
};
export const getAdminDashboard =
  (payload, callback, onError) => async (dispatch) => {
    // dispatch(getRequest());
    console.log("updating ngo with payload ", payload);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/adminDashboard/${payload}`
      );
      if (result.data.message) {
        console.log("failed in : ", result.data.message);
        // dispatch(getFailedTwo(result.data.message));
        onError(result.data.message);
      } else {
        console.log("success in : ", result.data);
        // dispatch(getAllNgo());
        callback(result.data);
      }
    } catch (error) {
      onError("Something went wrong");
    }
  };

import React, { useState } from 'react';
import { GenerateReport } from "../../../utils/api-factory";;

const App = () => {
  const [parsedData, setParsedData] = useState(null);
  const [studentDetails, setStudentDetails] = useState({ name: '', rollNumber: '' });
  const [reportPath, setReportPath] = useState('');

  const handleFileUpload = (fileData) => {
    setParsedData(fileData);
  };

  const handleDetailsChange = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };

  const handleReportGenerated = (path) => {
    setReportPath(path);
  };

  return (
    <div>
      <input type="text" name="name" placeholder="Student Name" onChange={handleDetailsChange} />
      <input type="text" name="rollNumber" placeholder="Roll Number" onChange={handleDetailsChange} />
      <button onClick={() => handleFileUpload({ example: 'fileData' })}>Upload File</button>
      <GenerateReport
        parsedData={parsedData}
        studentDetails={studentDetails}
        onReportGenerated={handleReportGenerated}
      />
      {reportPath && <a href={reportPath} target="_blank" rel="noopener noreferrer">Download Report</a>}
    </div>
  );
};

export default App;

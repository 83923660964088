// import axios from "axios";

// export const getFacilities = (id, callback, onError) => {
//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/facilityList/${id}`)
//     .then((result) => {
//       if (result.data.message) {
//         onError("");
//       } else {
//         // dispatch(getSuccess(result.data));
//         callback(result.data);
//       }
//     })
//     .catch((e) => {
//       onError("something webt wrong");
//     });
// };
// export const getAllFacilitiesOnDashboard = (id, callback, onError) => {
//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/admin/facilities/${id}`)
//     .then((result) => {
//       if (result.data.message) {
//         onError("");
//       } else {
//         // dispatch(getSuccess(result.data));
//         callback(result.data);
//       }
//     })
//     .catch((e) => {
//       onError("something went wrong");
//     });
// };
// export const uploadDeployementProof = (payload, callback, onError) => {
//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/uploadFacilityProof`, payload)
//     .then((result) => {
//       if (result.data.message) {
//         onError("Not uploaded,try again");
//       } else {
//         // dispatch(getSuccess(result.data));
//         callback("uploaded successfully");
//       }
//     })
//     .catch((e) => {
//       onError("something went wrong");
//     });
// };
// export const fetchAllDonors = (callback, onError) => {
//   const userId = JSON.parse(localStorage.getItem("user"))?._id;
//   console.log("Requesting with User ID: ", userId);
//   axios
//     .get(
//       `${process.env.REACT_APP_BASE_URL}/admin/getAllDonor?id=${
//         JSON.parse(localStorage.getItem("user"))._id
//       }`
//     )

//     .then((result) => {
//       callback(result.data.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };

// export const fetchAllDonors2 = (callback, onError) => {
//   const userId = JSON.parse(localStorage.getItem("user"))?._id;
//   console.log("Requesting with User ID: ", userId); // Log the user ID for debugging

//   if (!userId) {
//     onError({ message: "User ID not found in localStorage" });
//     return;
//   }

//   axios
//     .get(
//       `${process.env.REACT_APP_BASE_URL}/ngo/ImpactAssesment?id=${userId}`
//     )
//     .then((result) => {
//       console.log("Response data: ", result.data); // Log the successful response
//       callback(result.data.data);
//     })
//     .catch((e) => {
//       console.error("Error during API request:", e); // Log the complete error

//       if (e.response) {
//         console.error("API Error response:", e.response.data);
//         onError(e.response.data);
//       } else {
//         console.error("Error message:", e.message);
//         onError({ message: e.message || "An error occurred" });
//       }
//     });
// };






// // export const fetchAllDonors = (callback, onError) => {
// //   const userId = JSON.parse(localStorage.getItem("user"))?._id;
// //   if (!userId) {
// //     console.error("User ID is missing");
// //     return onError("User ID is missing");
// //   }

// //   // Call both endpoints in parallel using Promise.all
// //   Promise.all([
// //     axios.get(`http://localhost:3001/ngo/ImpactAssesment?id=${userId}`),
// //     axios.get(`http://localhost:3001/admin/getAllDonor?id=${userId}`)  // assuming 5000 is the port for the admin API
// //   ])
// //     .then(([ngoResponse, adminResponse]) => {
// //       const donors = {
// //         ngoDonors: ngoResponse.data.data,
// //         adminDonors: adminResponse.data.data
// //       };
// //       callback(donors); // callback with both donor datasets
// //     })
// //     .catch((error) => {
// //       console.error(error);
// //       // Improved error handling to check for different error response formats
// //       const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred";
// //       onError(errorMessage);  // Send the error message to the callback function
// //     });
// // };






// export const deleteDonor = (id, callback, onError) => {
//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/admin/getAllDonor?id=${id}`)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const updateDonor = (id, payload, callback, onError) => {
//   axios
//     .post(
//       `${process.env.REACT_APP_BASE_URL}/admin/updateDonor?id=${id}`,
//       payload
//     )
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const addDonor = (payload, callback, onError) => {
//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/admin/addDonor`, payload)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const getDonorInfo = (id, callback, onError) => {
//   // conso;
//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/donorInfo?id=${id}`)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const getGalleryImages = (payload, callback, onError) => {
//   // conso;
//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/NGO/getGallery`, payload)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const uploadGalleryImages = (payload, callback, onError) => {
//   // conso;

//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/NGO/uploadPhoto`, payload, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const addBlog = (payload, callback, onError) => {
//   // conso;

//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/blog/add`, payload, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const getBlogs = (callback, onError) => {
//   // conso;

//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/blogs`)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const deleteBlogById = (payload, callback, onError) => {
//   // conso;

//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/blog/delete`, payload)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const updateBlog = (id, payload, callback, onError) => {
//   // conso;

//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/blog/update?id=${id}`, payload, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const getEachBlog = (id, callback, onError) => {
//   // conso;

//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/eachBlog?id=${id}`)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const uploadStudentPhoto = (id, payload, callback, onError) => {
//   // conso;

//   axios
//     .post(
//       `${process.env.REACT_APP_BASE_URL}/uploadStudentPhoto?id=${id}`,
//       payload,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     )
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const uploadTeacherPhoto = (id, payload, callback, onError) => {
//   // conso;

//   axios
//     .post(
//       `${process.env.REACT_APP_BASE_URL}/uploadTeacherPhoto?id=${id}`,
//       payload,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     )
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const urlConverter = (id, payload, callback, onError) => {
//   // conso;

//   axios
//     .post(
//       `${process.env.REACT_APP_BASE_URL}/eraahUrlConverter?id=${id}`,
//       payload,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     )
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const fetchTeachersForAttendance = (id, callback, onError) => {
//   // conso;

//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/attendance/getTeachers?id=${id}`)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };
// export const addAttendence = (id, payload, callback, onError) => {
//   // conso;

//   axios
//     .post(
//       `${process.env.REACT_APP_BASE_URL}/TeacherAttendance?id=${id}`,
//       payload
//     )
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";



export const getFacilities = (id, callback, onError) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/facilityList/${id}`)
    .then((result) => {
      if (result.data.message) {
        onError("");
      } else {
        // dispatch(getSuccess(result.data));
        callback(result.data);
      }
    })
    .catch((e) => {
      onError("something webt wrong");
    });
};
export const getAllFacilitiesOnDashboard = (id, callback, onError) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/admin/facilities/${id}`)
    .then((result) => {
      if (result.data.message) {
        onError("");
      } else {
        // dispatch(getSuccess(result.data));
        callback(result.data);
      }
    })
    .catch((e) => {
      onError("something went wrong");
    });
};
export const uploadDeployementProof = (payload, callback, onError) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/uploadFacilityProof`, payload)
    .then((result) => {
      if (result.data.message) {
        onError("Not uploaded,try again");
      } else {
        // dispatch(getSuccess(result.data));
        callback("uploaded successfully");
      }
    })
    .catch((e) => {
      onError("something went wrong");
    });
};



// export const fetchAllDonors = (callback, onError) => {
//   axios
//     .get(
//       `${process.env.REACT_APP_BASE_URL}/admin/getAllDonor?id=${
//         JSON.parse(localStorage.getItem("user"))._id
//       }`
//     )
//     .then((result) => {
//       callback(result.data.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });
// };


export const fetchAllDonors = (userId, callback, onError) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/admin/getAllDonor?id=${userId}`)
    .then((result) => {
      callback(result.data.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};



// export const fetchAllDonors1 = (callback, onError) => {
//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/getAllDonors`)
//     .then((result) => {
//       callback(result.data.data);
//     })
//     .catch((e) => {
//       console.error("Error fetching donors:", e.response ? e.response.data : e.message); // Add detailed error logging
//       onError(e);
//     });
// }; //I ADDED FOR DROP DOWN


// for displaying student of perticular donors
// export const fetchStudentsByDonor = (donor_id, successCallback, errorCallback) => {
//   fetch(`${process.env.REACT_APP_BASE_URL}/students?donor_id=${donor_id}`)
//     .then((res) => res.json())
//     .then(successCallback)
//     .catch(errorCallback);
// };

// export const fetchStudentsByDonor = (donor_id, onSuccess, onError) => {
//   console.log("in Fetch");
//   console.log(donor_id);
//   fetch(`${process.env.REACT_APP_BASE_URL}/students?donor_id=${donor_id}`)  // Change the URL to include donor_id in the path
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       return response.json();
//     })
//     .then((data) => {
//       if (data.error) {
//         console.error(`API Error: ${data.message}`);
//         onError(data.message);
//       } else {
//         onSuccess(data); // Assuming data contains the students array
//       }
//     })
//     .catch((err) => {
//       console.error(`Error fetching students: ${err.message}`);
//       onError("Unable to fetch students.");
//     });
// };

export const fetchStudentsByDonor = (donor_id, onSuccess, onError) => {
  console.log("Fetching students for donor ID:", donor_id);
  fetch(`${process.env.REACT_APP_BASE_URL}/students?donor_id=${donor_id}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("API Response:", data);
      if (Array.isArray(data) && data.length === 0) {
        // Handle empty array case
        onSuccess([]); // Pass empty array to indicate no students found
      } else if (data.error) {
        console.error(`API Error: ${data.message}`);
        onError(data.message);
      } else {
        onSuccess(data); // Pass the students array
      }
    })
    .catch((err) => {
      console.error(`Error fetching students: ${err.message}`);
      onError("Unable to fetch students.");
    });
};







export const deleteDonor = (id, callback, onError) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/admin/getAllDonor?id=${id}`)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const updateDonor = (id, payload, callback, onError) => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/admin/updateDonor?id=${id}`,
      payload
    )
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const addDonor = (payload, callback, onError) => {
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/ngo/addDonor`, payload)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};


// export const addDonor = (payload, callback, onError) => {
  // const { currentUser } = useSelector((state) => state.user);
  // // Get NGO ID from localStorage or your auth state management
  // const ngoId = currentUser._id; // or however you store the NGO ID
  
  // // Add NGO_id to the payload
  // const donorPayload = {
  //   ...payload,
  //   NGO_id: ngoId
  // };

//   // You can either send it in the payload
//   axios
//     .post(`${process.env.REACT_APP_BASE_URL}/ngo/addDonor`, donorPayload)
//     .then((result) => {
//       callback(result.data);
//     })
//     .catch((e) => {
//       console.log(e);
//       onError(e);
//     });

//   // OR send it as a query parameter
//   // axios
//   //   .post(`${process.env.REACT_APP_BASE_URL}/ngo/addDonor?ngoId=${ngoId}`, payload)
//   //   .then((result) => {
//   //     callback(result.data);
//   //   })
//   //   .catch((e) => {
//   //     console.log(e);
//   //     onError(e);
//   //   });
// };


export const getDonorInfo = (id, callback, onError) => {
  // conso;
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/donorInfo?id=${id}`)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const getGalleryImages = (payload, callback, onError) => {
  // conso;
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/NGO/getGallery`, payload)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const uploadGalleryImages = (payload, callback, onError) => {
  // conso;

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/NGO/uploadPhoto`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const addBlog = (payload, callback, onError) => {
  // conso;

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/blog/add`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const getBlogs = (callback, onError) => {
  // conso;

  axios
    .get(`${process.env.REACT_APP_BASE_URL}/blogs`)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const deleteBlogById = (payload, callback, onError) => {
  // conso;

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/blog/delete`, payload)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const updateBlog = (id, payload, callback, onError) => {
  // conso;

  axios
    .post(`${process.env.REACT_APP_BASE_URL}/blog/update?id=${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const getEachBlog = (id, callback, onError) => {
  // conso;

  axios
    .get(`${process.env.REACT_APP_BASE_URL}/eachBlog?id=${id}`)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const uploadStudentPhoto = (id, payload, callback, onError) => {
  // conso;

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/uploadStudentPhoto?id=${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const uploadTeacherPhoto = (id, payload, callback, onError) => {
  // conso;

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/uploadTeacherPhoto?id=${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const urlConverter = (id, payload, callback, onError) => {
  // conso;

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/eraahUrlConverter?id=${id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const fetchTeachersForAttendance = (id, callback, onError) => {
  // conso;

  axios
    .get(`${process.env.REACT_APP_BASE_URL}/attendance/getTeachers?id=${id}`)
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};
export const addAttendence = (id, payload, callback, onError) => {
  // conso;

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/TeacherAttendance?id=${id}`,
      payload
    )
    .then((result) => {
      callback(result.data);
    })
    .catch((e) => {
      console.log(e);
      onError(e);
    });
};

// impact report maker api
export const GenerateReport = ({ studentDetails, parsedData, callback, onError }) => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/generate-report`,
      { studentDetails, parsedData },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => {
      // Call callback function with result data when the API call is successful
      callback(result.data);
    })
    .catch((e) => {
      console.log("Error generating report:", e);
      
      // If error has response object, log more detailed error info
      if (e.response) {
        console.error("Error Response:", e.response.data);
        onError(e.response.data);  // Provide more error details to the onError function
      } else {
        onError(e.message);  // Provide error message if there's no response from the server
      }
    });
};


// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//     status: 'idle',
//     userDetails: [],
//     tempDetails: [],
//     loading: false,
//     currentUser: JSON.parse(localStorage.getItem('user')) || null,
//     currentRole: (JSON.parse(localStorage.getItem('user')) || {}).role || null,
//     error: null,
//     response: null,
//     darkMode: true
// };

// const userSlice = createSlice({
//     name: 'user',
//     initialState,
//     reducers: {
//         setUser: (state, action) => {
//             state.currentRole = action.payload.role;
//             state.token = action.payload.token;
//             state.userId = action.payload.id;
//             // state.currentUser = action.payload;
//           },
//         authRequest: (state) => {
//             state.status = 'loading';
//         },
//         underControl: (state) => {
//             state.status = 'idle';
//             state.response = null;
//         },
//         stuffAdded: (state, action) => {
//             state.status = 'added';
//             state.response = null;
//             state.error = null;
//             state.tempDetails = action.payload;
//         },
//         authSuccess: (state, action) => {
//             console.log('authSuccess payload:', action.payload); 
//             state.status = 'success';
//             state.currentUser = action.payload;
//             state.currentRole = action.payload.role;
//             localStorage.setItem('user', JSON.stringify(action.payload));
//             state.response = null;
//             state.error = null;
//         },
//         authFailed: (state, action) => {
//             state.status = 'failed';
//             state.response = action.payload;
//         },
//         authError: (state, action) => {
//             state.status = 'error';
//             state.error = action.payload;
//         },
//         authLogout: (state) => {
//             localStorage.removeItem('user');
//             state.currentUser = null;
//             state.status = 'idle';
//             state.error = null;
//             state.currentRole = null
//         },

//         doneSuccess: (state, action) => {
//             state.userDetails = action.payload;
//             state.loading = false;
//             state.error = null;
//             state.response = null;
//         },
//         getDeleteSuccess: (state) => {
//             state.loading = false;
//             state.error = null;
//             state.response = null;
//         },

//         getRequest: (state) => {
//             state.loading = true;
//         },
//         getFailed: (state, action) => {
//             state.response = action.payload;
//             state.loading = false;
//             state.error = null;
//         },
//         getError: (state, action) => {
//             state.loading = false;
//             state.error = action.payload;
//         },
//         toggleDarkMode: (state) => {
//             state.darkMode = !state.darkMode;
//         }
//     },
// });

// export const {
//     authRequest,
//     underControl,
//     stuffAdded,
//     authSuccess,
//     authFailed,
//     authError,
//     authLogout,
//     doneSuccess,
//     getDeleteSuccess,
//     getRequest,
//     getFailed,
//     getError,
//     toggleDarkMode
// } = userSlice.actions;

// export const { setUser } = userSlice.actions;
// export const userReducer = userSlice.reducer;





// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//     status: 'idle',
//     userDetails: [],
//     tempDetails: [],
//     loading: false,
//     currentUser: null, // Avoid reading from localStorage initially
//     currentRole: null, // Avoid using localStorage here
//     error: null,
//     response: null,
//     darkMode: true,
// };

// const userSlice = createSlice({
//     name: 'user',
//     initialState,
//     reducers: {
//         setUser: (state, action) => {
//             const { role, token, id } = action.payload;
//             state.currentRole = role;
//             state.token = token;
//             state.userId = id;  // If needed
//             state.currentUser = action.payload;  // Store full user details
//         },
//         authRequest: (state) => {
//             state.status = 'loading';
//         },
//         underControl: (state) => {
//             state.status = 'idle';
//             state.response = null;
//         },
//         stuffAdded: (state, action) => {
//             state.status = 'added';
//             state.response = null;
//             state.error = null;
//             state.tempDetails = action.payload;
//         },
//         authSuccess: (state, action) => {
//             console.log('authSuccess payload:', action.payload);
//             state.status = 'success';
//             state.currentUser = action.payload;
//             state.currentRole = action.payload.role;
//             state.token = action.payload.token;  // Store token
//             localStorage.setItem('user', JSON.stringify(action.payload));  // Sync with localStorage
//             state.response = null;
//             state.error = null;
//         },
//         authFailed: (state, action) => {
//             state.status = 'failed';
//             state.response = action.payload;
//         },
//         authError: (state, action) => {
//             state.status = 'error';
//             state.error = action.payload;
//         },
//         authLogout: (state) => {
//             localStorage.removeItem('user');
//             state.currentUser = null;
//             state.token = null;  // Reset token as well
//             state.status = 'idle';
//             state.error = null;
//             state.currentRole = null;
//         },
//         doneSuccess: (state, action) => {
//             state.userDetails = action.payload;
//             state.loading = false;
//             state.error = null;
//             state.response = null;
//         },
//         getDeleteSuccess: (state) => {
//             state.loading = false;
//             state.error = null;
//             state.response = null;
//         },
//         getRequest: (state) => {
//             state.loading = true;
//         },
//         getFailed: (state, action) => {
//             state.response = action.payload;
//             state.loading = false;
//             state.error = null;
//         },
//         getError: (state, action) => {
//             state.loading = false;
//             state.error = action.payload;
//         },
//         toggleDarkMode: (state) => {
//             state.darkMode = !state.darkMode;
//         }
//     },
// });

// export const {
//     authRequest,
//     underControl,
//     stuffAdded,
//     authSuccess,
//     authFailed,
//     authError,
//     authLogout,
//     doneSuccess,
//     getDeleteSuccess,
//     getRequest,
//     getFailed,
//     getError,
//     toggleDarkMode
// } = userSlice.actions;

// export const { setUser } = userSlice.actions;
// export const userReducer = userSlice.reducer;



import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: 'idle',
    userDetails: [],
    tempDetails: [],
    loading: false,
    currentUser: null, // Avoid reading from localStorage initially
    currentRole: null, // Avoid using localStorage here
    token: null, // Initialize token as null
    error: null,
    response: null,
    darkMode: true,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { role, token, _id } = action.payload; // Use `_id` instead of `id`
            state.currentRole = role;
            state.token = token;
            state.userId = _id; // If needed
            state.currentUser = action.payload; // Store full user details
        },
        authRequest: (state) => {
            state.status = 'loading';
        },
        underControl: (state) => {
            state.status = 'idle';
            state.response = null;
        },
        stuffAdded: (state, action) => {
            state.status = 'added';
            state.response = null;
            state.error = null;
            state.tempDetails = action.payload;
        },
        authSuccess: (state, action) => {
            console.log('authSuccess payload:', action.payload);
            state.status = 'success';
            state.currentUser = action.payload;
            state.currentRole = action.payload.role;
            state.token = action.payload.token; // Store token
            localStorage.setItem('user', JSON.stringify(action.payload)); // Sync with localStorage
            state.response = null;
            state.error = null;
        },
        authFailed: (state, action) => {
            state.status = 'failed';
            state.response = action.payload;
        },
        authError: (state, action) => {
            state.status = 'error';
            state.error = action.payload;
        },
        authLogout: (state) => {
            localStorage.removeItem('user');
            state.currentUser = null;
            state.token = null; // Reset token as well
            state.status = 'idle';
            state.error = null;
            state.currentRole = null;
        },
        doneSuccess: (state, action) => {
            state.userDetails = action.payload;
            state.loading = false;
            state.error = null;
            state.response = null;
        },
        getDeleteSuccess: (state) => {
            state.loading = false;
            state.error = null;
            state.response = null;
        },
        getRequest: (state) => {
            state.loading = true;
        },
        getFailed: (state, action) => {
            state.response = action.payload;
            state.loading = false;
            state.error = null;
        },
        getError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
        }
    },
});

export const {
    authRequest,
    underControl,
    stuffAdded,
    authSuccess,
    authFailed,
    authError,
    authLogout,
    doneSuccess,
    getDeleteSuccess,
    getRequest,
    getFailed,
    getError,
    toggleDarkMode
} = userSlice.actions;

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;

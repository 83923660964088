// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser } from "../../../redux/userRelated/userHandle";
// import Popup from "../../../components/Popup";
// import { underControl } from "../../../redux/userRelated/userSlice";
// import { getAllSclasses } from "../../../redux/sclassRelated/sclassHandle";
// import { CircularProgress } from "@mui/material";
// import UploadCsv from "../../../components/uploadCsv/uploadCsv";

// const AddStudent = ({ situation }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const params = useParams();

//   const userState = useSelector((state) => state.user);
//   const { status, currentUser, response, error } = userState;
//   const { sclassesList } = useSelector((state) => state.sclass);

//   const [csvTab, setCsvTab] = useState(false);
//   const [name, setName] = useState("");
//   const [rollNum, setRollNum] = useState("");
//   const [motherName, setMotherName] = useState("");
//   const [fatherName, setFatherName] = useState("");
//   const [motherOcc, setMotherOcc] = useState("");
//   const [fatherOcc, setFatherOcc] = useState("");
//   const [photo, setPhoto] = useState(null);
//   const [className, setClassName] = useState("");
//   const [sclassName, setSclassName] = useState("");

//   const NGOID = currentUser._id;
//   const role = "Student";
//   const attendance = [];

//   useEffect(() => {
//     if (situation === "Class") {
//       setSclassName(params.id);
//     }
//   }, [params.id, situation]);

//   const [showPopup, setShowPopup] = useState(false);
//   const [message, setMessage] = useState("");
//   const [loader, setLoader] = useState(false);

//   useEffect(() => {
//     dispatch(getAllSclasses(NGOID, "Sclass"));
//   }, [NGOID, dispatch]);

//   const changeHandler = (event) => {
//     if (event.target.value === "Select Class") {
//       setClassName("Select Class");
//       setSclassName("");
//     } else {
//       const selectedClass = sclassesList.find(
//         (classItem) => classItem.sclassName === event.target.value
//       );
//       setClassName(selectedClass.sclassName);
//       setSclassName(selectedClass._id);
//     }
//   };

//   const fields = {
//     name,
//     rollNum,
//     fatherName,
//     motherName,
//     motherOcc,
//     fatherOcc,
//     photo,
//     sclassName,
//     NGOID,
//     role,
//     attendance,
//   };

//   const submitHandler = (event) => {
//     event.preventDefault();
//     if (sclassName === "") {
//       setMessage("Please select a classname");
//       setShowPopup(true);
//     } else {
//       setLoader(true);
//       dispatch(registerUser(fields, role));
//     }
//   };

//   useEffect(() => {
//     if (status === "added") {
//       dispatch(underControl());
//       navigate(-1);
//     } else if (status === "failed") {
//       setMessage(response);
//       setShowPopup(true);
//       setLoader(false);
//     } else if (status === "error") {
//       setMessage("Network Error");
//       setShowPopup(true);
//       setLoader(false);
//     }
//   }, [status, navigate, error, response, dispatch]);

//   return (
//     <>
//       <div className="tabsContainer col-lg-4 col-11 col-md-8 col-sm-11 m-auto mb-4">
//         <div
//           className="aggDetailsTab"
//           style={!csvTab ? { borderBottom: "3px solid #2B78FF" } : {}}
//         >
//           <a
//             className="tabText"
//             style={!csvTab ? { color: "#2B78FF", fontWeight: 700 } : {}}
//             onClick={() => setCsvTab(false)}
//           >
//             Add Student
//           </a>
//         </div>

//         <div
//           className="aggDetailsTab"
//           style={csvTab ? { borderBottom: "3px solid #2B78FF" } : {}}
//         >
//           <a
//             className="tabText"
//             style={csvTab ? { color: "#2B78FF", fontWeight: 700 } : {}}
//             onClick={() => setCsvTab(true)}
//           >
//             Add Via CSV
//           </a>
//         </div>
//       </div>
//       {csvTab ? (
//         <UploadCsv actionFor={"student"} />
//       ) : (
//         <div
//           className="col-lg-6 col-sm-12 col-md-8 col-12 m-auto"
//           // style={{ paddingTop: "12rem", paddingBottom: "2rem" }}
//         >
//           <form className="registerForm mt-3" onSubmit={submitHandler}>
//             <span className="registerTitle">Add Student</span>
//             <label>Name</label>
//             <input
//               className="registerInput"
//               type="text"
//               placeholder="Enter student's name..."
//               value={name}
//               onChange={(event) => setName(event.target.value)}
//               autoComplete="name"
//               required
//             />

//             {situation === "Student" && (
//               <>
//                 <label>Class</label>
//                 <select
//                   className="registerInput"
//                   value={className}
//                   onChange={changeHandler}
//                   required
//                 >
//                   <option value="Select Class">Select Class</option>
//                   {sclassesList.map((classItem, index) => (
//                     <option key={index} value={classItem.sclassName}>
//                       {classItem.sclassName}
//                     </option>
//                   ))}
//                 </select>
//               </>
//             )}

//             <label>Roll Number</label>
//             <input
//               className="registerInput"
//               type="text"
//               placeholder="Enter student's Roll Number..."
//               value={rollNum}
//               onChange={(event) => setRollNum(event.target.value)}
//               required
//             />

//             <label>Mother's Name</label>
//             <input
//               className="registerInput"
//               type="text"
//               placeholder="Enter student's mother's name..."
//               value={motherName}
//               onChange={(event) => setMotherName(event.target.value)}
//               autoComplete="new-password"
//               // required
//             />
//             <label>Mother's Occupation</label>
//             <input
//               className="registerInput"
//               type="text"
//               placeholder="Enter student's mother's occupation"
//               value={motherOcc}
//               onChange={(event) => setMotherOcc(event.target.value)}
//               autoComplete="new-password"
//               // required
//             />
//             <label>Father's Name</label>
//             <input
//               className="registerInput"
//               type="text"
//               placeholder="Enter student's father's name..."
//               value={fatherName}
//               onChange={(event) => setFatherName(event.target.value)}
//               autoComplete="new-password"
//               // required
//             />
//             <label>Father's Occupation</label>
//             <input
//               className="registerInput"
//               type="text"
//               placeholder="Enter student's father's occupation"
//               value={fatherOcc}
//               onChange={(event) => setFatherOcc(event.target.value)}
//               autoComplete="new-password"
//               // required
//             />
//             <label>Student Photo</label>
//             <input
//               className="registerInput"
//               type="file"
//               placeholder="Upload Photo"
//               onChange={(event) => setPhoto(event.target.files[0])}
//               required
//             />

//             <button
//               className="registerButton my-2"
//               type="submit"
//               disabled={loader}
//             >
//               {loader ? <CircularProgress size={24} color="inherit" /> : "Add"}
//             </button>
//           </form>
//         </div>
//       )}
//       <Popup
//         message={message}
//         setShowPopup={setShowPopup}
//         showPopup={showPopup}
//       />
//     </>
//   );
// };

// export default AddStudent;


import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/userRelated/userHandle";
import Popup from "../../../components/Popup";
import { underControl } from "../../../redux/userRelated/userSlice";
import { getAllSclasses } from "../../../redux/sclassRelated/sclassHandle";
import { CircularProgress } from "@mui/material";
import UploadCsv from "../../../components/uploadCsv/uploadCsv";
import { fetchAllDonors1 } from "../../../utils/api-factory"; // Import the fetchAllDonors function
import { fetchAllDonors } from "../../../utils/api-factory"; // Import the fetchAllDonors function

const AddStudent = ({ situation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const userState = useSelector((state) => state.user);
  const { status, currentUser, response, error } = userState;
  const { sclassesList } = useSelector((state) => state.sclass);


  const [csvTab, setCsvTab] = useState(false);
  const [name, setName] = useState("");
  const [rollNum, setRollNum] = useState("");
  const [motherName, setMotherName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherOcc, setMotherOcc] = useState("");
  const [fatherOcc, setFatherOcc] = useState("");
  const [photo, setPhoto] = useState(null);
  const [className, setClassName] = useState("");
  const [sclassName, setSclassName] = useState("");
  const [donorName, setDonorName] = useState(""); 
  const [donors, setDonors] = useState([]); // Add this line
  const [loading, setLoading] = useState(false); // Add this line
  const [fetchError, setFetchError] = useState(null); // Add this line

  const NGOID = currentUser._id;
  const role = "Student";
  const attendance = [];

  useEffect(() => {
    if (situation === "Class") {
      setSclassName(params.id);
    }
  }, [params.id, situation]);

  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getAllSclasses(NGOID, "Sclass"))
  
  }, [NGOID, dispatch]);

  useEffect(() => {
    setLoading(true);
    fetchAllDonors(
      currentUser._id,
      (data) => {
        setDonors(data);
        setLoading(false);
      },
      (error) => {
        setFetchError(error);
        setLoading(false);
      }
    );
  }, []);

  const changeHandler = (event) => {
    if (event.target.value === "Select Class") {
      setClassName("Select Class");
      setSclassName("");
    } else {
      const selectedClass = sclassesList.find(
        (classItem) => classItem.sclassName === event.target.value
      );
      setClassName(selectedClass.sclassName);
      setSclassName(selectedClass._id);
    }
  };

  const donorChangeHandler = (event) => {
    setDonorName(event.target.value); // Ensure this is used
  };

  const fields = {
    name,
    rollNum,
    fatherName,
    motherName,
    motherOcc,
    fatherOcc,
    photo,
    sclassName,
    donor: donorName, //ADDED DONOR NAME
    NGOID,
    role,
    attendance,
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (sclassName === "") {
      setMessage("Please select a classname");
      setShowPopup(true);
    } else {
      setLoader(true);
      dispatch(registerUser(fields, role));
    }
  };

  useEffect(() => {
    if (status === "added") {
      dispatch(underControl());
      navigate(-1);
    } else if (status === "failed") {
      setMessage(response);
      setShowPopup(true);
      setLoader(false);
    } else if (status === "error") {
      setMessage("Network Error");
      setShowPopup(true);
      setLoader(false);
    }
  }, [status, navigate, error, response, dispatch]);

  return (
    <>
      <div className="tabsContainer col-lg-4 col-11 col-md-8 col-sm-11 m-auto mb-4">
        <div
          className="aggDetailsTab"
          style={!csvTab ? { borderBottom: "3px solid #2B78FF" } : {}}
        >
          <a
            className="tabText"
            style={!csvTab ? { color: "#2B78FF", fontWeight: 700 } : {}}
            onClick={() => setCsvTab(false)}
          >
            Add Student
          </a>
        </div>

        <div
          className="aggDetailsTab"
          style={csvTab ? { borderBottom: "3px solid #2B78FF" } : {}}
        >
          <a
            className="tabText"
            style={csvTab ? { color: "#2B78FF", fontWeight: 700 } : {}}
            onClick={() => setCsvTab(true)}
          >
            Add Via CSV
          </a>
        </div>
      </div>
      {csvTab ? (
        <UploadCsv actionFor={"student"} />
      ) : (
        <div
          className="col-lg-6 col-sm-12 col-md-8 col-12 m-auto"
          // style={{ paddingTop: "12rem", paddingBottom: "2rem" }}
        >
          <form className="registerForm mt-3" onSubmit={submitHandler}>
            <span className="registerTitle">Add Student</span>
            <label>Name</label>
            <input
              className="registerInput"
              type="text"
              placeholder="Enter student's name..."
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoComplete="name"
              required
            />

            {situation === "Student" && (
              <>
                <label>Class</label>
                <select
                  className="registerInput"
                  value={className}
                  onChange={changeHandler}
                  required
                >
                  <option value="Select Class">Select Class</option>
                  {sclassesList.map((classItem, index) => (
                    <option key={index} value={classItem.sclassName}>
                      {classItem.sclassName}
                    </option>
                  ))}
                </select>
              </>
            )}

            <label>Donor</label>
            <select
              className="registerInput"
              value={donorName}
              onChange={donorChangeHandler} // Ensure this is used
              required
            >
              <option value="">Select Donor</option>
              {Array.isArray(donors) &&
                donors.map((donor, index) => (
                  <option key={index} value={donor._id}>
                    {donor.name}
                  </option>
                ))}
            </select>

            {fetchError && <p>Error fetching donors: {fetchError.message}</p>} {/* Add error handling */}

            

            <label>Roll Number</label>
            <input
              className="registerInput"
              type="text"
              placeholder="Enter student's Roll Number..."
              value={rollNum}
              onChange={(event) => setRollNum(event.target.value)}
              required
            />

            <label>Mother's Name</label>
            <input
              className="registerInput"
              type="text"
              placeholder="Enter student's mother's name..."
              value={motherName}
              onChange={(event) => setMotherName(event.target.value)}
              autoComplete="new-password"
              // required
            />
            <label>Mother's Occupation</label>
            <input
              className="registerInput"
              type="text"
              placeholder="Enter student's mother's occupation"
              value={motherOcc}
              onChange={(event) => setMotherOcc(event.target.value)}
              autoComplete="new-password"
              // required
            />
            <label>Father's Name</label>
            <input
              className="registerInput"
              type="text"
              placeholder="Enter student's father's name..."
              value={fatherName}
              onChange={(event) => setFatherName(event.target.value)}
              autoComplete="new-password"
              // required
            />
            <label>Father's Occupation</label>
            <input
              className="registerInput"
              type="text"
              placeholder="Enter student's father's occupation"
              value={fatherOcc}
              onChange={(event) => setFatherOcc(event.target.value)}
              autoComplete="new-password"
              // required
            />
            <label>Student Photo</label>
            <input
              className="registerInput"
              type="file"
              placeholder="Upload Photo"
              onChange={(event) => setPhoto(event.target.files[0])}
              required
            />

            <button
              className="registerButton my-2"
              type="submit"
              disabled={loader}
            >
              {loader ? <CircularProgress size={24} color="inherit" /> : "Add"}
            </button>
          </form>
        </div>
      )}
      <Popup
        message={message}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
      />
    </>
  );
};

export default AddStudent;